import { range } from "lodash";

export const getOneMonthAgo = () => {
  const currentDate = new Date();
  const oneMonthAgo = new Date(currentDate);
  oneMonthAgo.setMonth(currentDate.getMonth() - 1);
  return oneMonthAgo;
};

export const getYears = () =>
  range(new Date().getFullYear() + 1, 1999).map((y) => y.toString());

export const convertLocalTimeAndUTC = ({
  time,
  isLocalToUTC,
}: {
  time?: {
    hour: number;
    minute: number;
  };
  isLocalToUTC?: boolean;
}) => {
  if (!time) {
    return;
  }

  const date = new Date();
  const timezoneOffset = date.getTimezoneOffset() / 60;

  if (isLocalToUTC) {
    date.setHours(time.hour + timezoneOffset);
  } else {
    date.setHours(time.hour - timezoneOffset);
  }
  date.setMinutes(time.minute);

  return {
    hour: date.getHours(),
    minute: date.getMinutes(),
  };
};

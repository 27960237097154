import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { Button } from "@nextui-org/react";
import { cn } from "@nextui-org/react";
import mergeRefs from "merge-refs";
import { forwardRef, ReactElement, Ref, RefAttributes } from "react";

import { Tooltip } from "../Tooltip/Tooltip";
import { DropdownOptionValue, DropdownTriggerProps } from "./types";

// Just forwardRef/generics boilerplate
export const DropdownTrigger = forwardRef(DropdownTriggerBase) as unknown as <
  T extends DropdownOptionValue,
>(
  props: DropdownTriggerProps<T> & RefAttributes<HTMLButtonElement>
) => ReactElement;

function DropdownTriggerBase<T extends DropdownOptionValue>(
  {
    placeholder,
    options,
    value,
    className,
    triggerRef,
    isLoading,
    error,
    ...props
  }: DropdownTriggerProps<T>,
  ref: Ref<HTMLButtonElement>
) {
  const selectedValues = new Set(
    value === undefined ? [] : Array.isArray(value) ? value : [value]
  );
  const selectedLabels = options
    .filter((o) => selectedValues.has(o.value))
    .map((o) => o.label);
  const missingOptionsNumber = selectedValues.size - selectedLabels.length;
  const label =
    selectedLabels.length === 0
      ? missingOptionsNumber > 0
        ? `${missingOptionsNumber} selection(s)`
        : ""
      : missingOptionsNumber > 0
        ? `${selectedLabels.join(", ")} and ${missingOptionsNumber} more selection(s)`
        : selectedLabels.join(", ");

  return (
    <div className="w-fit">
      <Button
        {...props}
        variant="faded"
        radius="sm"
        className={cn("w-60", error ? "border-red-500" : "", className)}
        ref={mergeRefs(ref, triggerRef)}
        isLoading={isLoading}
      >
        <span className="flex w-full items-center justify-between overflow-hidden">
          <span className="flex items-center gap-2 overflow-hidden">
            {selectedValues.size > 0 ? (
              <Tooltip
                showArrow
                color="foreground"
                delay={1000}
                closeDelay={0}
                content={<div className="max-w-64 p-2">{label}</div>}
              >
                <span className="truncate">{label}</span>
              </Tooltip>
            ) : (
              <span className="truncate font-normal text-gray-900">
                {placeholder}
              </span>
            )}
          </span>
          <ChevronDownIcon className="size-4 flex-none text-gray-700" />
        </span>
      </Button>
      {error && (
        <div role="alert" className="mt-1 text-sm text-red-500">
          {error}
        </div>
      )}
    </div>
  );
}

import {
  Listbox,
  ListboxItem,
  ListboxItemProps,
  ListboxSection,
} from "@nextui-org/react";
import { keyBy } from "lodash-es";
import { FC, Key, SVGProps } from "react";

type Color = Exclude<ListboxItemProps["color"], undefined>;

const itemStyles: Record<Color, string> = {
  default: "text-default",
  primary: "text-primary",
  secondary: "text-secondary",
  success: "text-success",
  warning: "text-warning",
  danger: "text-danger",
};

export type MenuItem<T extends string = string> = {
  key: T;
  label: string;
  Icon?: FC<SVGProps<SVGSVGElement>>;
  color?: Color;
  isDisabled?: boolean;
  isHidden?: boolean;
  section?: string;
} & (
  | {
      href: string;
      onAction?: never;
    }
  | {
      href?: never;
      onAction: () => void;
    }
);

export type MenuProps<T extends string = string> = {
  title?: string;
  items: MenuItem<T>[];
  showSectionTitle?: boolean;
  displayIconAfterText?: boolean;
};

export function Menu<T extends string = string>({
  title,
  items,
  showSectionTitle,
  displayIconAfterText,
}: MenuProps<T>) {
  const visibleItems = items.filter((item) => !item.isHidden);
  const itemsMap = keyBy(visibleItems, "key");
  const handleAction = (key: Key) => {
    const item = itemsMap[key as T];

    if (item && item.onAction) {
      item.onAction();
    }
  };
  const disabledKeys = visibleItems
    .filter((item) => item.isDisabled)
    .map((item) => item.key);

  const sections = visibleItems.reduce<
    { name: string; items: MenuItem<T>[] }[]
  >((acc, item) => {
    const lastSection = acc.at(-1);
    const name = item.section ?? "";

    if (lastSection && lastSection.name === name) {
      lastSection.items.push(item);
    } else {
      acc.push({ name, items: [item] });
    }

    return acc;
  }, []);

  const content = sections.map((section, i) => {
    const { name, items } = section;

    return (
      <ListboxSection
        key={`${name}-${i}`}
        title={showSectionTitle ? name : undefined}
        showDivider={i !== sections.length - 1}
        classNames={{ divider: "my-0.5", base: "m-0" }}
      >
        {items.map((item) => {
          const { Icon, label, key, color, href } = item;

          return (
            <ListboxItem
              aria-label={label}
              startContent={
                Icon && !displayIconAfterText ? (
                  <Icon className="size-4" role="img" />
                ) : undefined
              }
              endContent={
                Icon && displayIconAfterText ? (
                  <Icon className="size-4" role="img" />
                ) : undefined
              }
              key={key}
              title={label}
              color={color}
              className={color ? itemStyles[color] : undefined}
              href={href}
              classNames={{ base: "py-2 px-3 rounded-none" }}
            />
          );
        })}
      </ListboxSection>
    );
  });

  return (
    <Listbox
      aria-label={title ?? "menu"}
      onAction={handleAction}
      disabledKeys={disabledKeys}
      topContent={
        title ? (
          <div className="px-3 pt-3 text-sm font-normal text-gray-500">
            {title}
          </div>
        ) : undefined
      }
      classNames={{ base: "p-0 max-h-96 overflow-y-auto" }}
    >
      {content}
    </Listbox>
  );
}

import {
  ChevronDownIcon,
  MagnifyingGlassCircleIcon,
} from "@heroicons/react/24/outline";
import {
  Button,
  ButtonProps,
  Input,
  Listbox,
  ListboxItem,
  Popover,
  PopoverContent,
  PopoverProps,
  PopoverTrigger,
  SelectionMode,
} from "@nextui-org/react";
import { compact } from "lodash-es";
import { ReactNode, useMemo, useState } from "react";
import { tv } from "tailwind-variants";

const variants = tv({
  slots: {
    trigger: "flex justify-between overflow-hidden",
  },
});
const { trigger } = variants();

export type SearchableDropdownOption = {
  value: string;
  label: ReactNode;
  searchableValue: string;
  selectAllOption?: boolean;
};

type InnerDropdownProps = Omit<ButtonProps, "onChange"> & {
  selectedValues?: string[];
  placeholder?: string;
  options: SearchableDropdownOption[];
  onChange: (values?: string[]) => void;
  classNames?: PopoverProps["classNames"];
  ariaLabel?: string;
  children?: ReactNode;
  preventClose?: boolean;
  selectionMode: SelectionMode;
  closeOnSelect?: boolean;
};

export type SearchableDropdownProps = Omit<
  InnerDropdownProps,
  "selectionMode" | "selectedValues" | "onChange"
> & {
  selectedValue?: string;
  onChange: (value?: string) => void;
  preventClose?: boolean;
};

export function SearchableDropdown({
  selectedValue,
  onChange,
  children,
  preventClose,
  ...props
}: SearchableDropdownProps) {
  const selectedKeys = useMemo(
    () => (selectedValue ? [selectedValue] : undefined),
    [selectedValue]
  );

  const handleChange = (values?: string[]) => {
    onChange([...(values || [])][0]);
  };

  return (
    <BaseDropdown
      {...props}
      selectedValues={selectedKeys}
      onChange={handleChange}
      children={children}
      preventClose={preventClose}
      selectionMode="single"
    />
  );
}

export type SearchableMultiDropdownProps = Omit<
  InnerDropdownProps,
  "selectionMode"
>;

export function SearchableMultiDropdown(props: SearchableMultiDropdownProps) {
  return <BaseDropdown {...props} selectionMode="multiple" />;
}

function BaseDropdown({
  selectedValues,
  placeholder,
  options,
  onChange,
  className,
  classNames,
  children,
  ariaLabel = "dropdown",
  preventClose,
  selectionMode,
  closeOnSelect,
  ...props
}: InnerDropdownProps) {
  const [searchString, setSearchString] = useState<string>("");
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const selectedOptions =
    compact(options.filter((v) => selectedValues?.includes(v.value))) || [];
  const label =
    selectedOptions.length > 0
      ? selectedOptions.map((v) => v.searchableValue).join(", ")
      : undefined;

  return (
    <Popover
      radius="sm"
      isOpen={isOpen}
      onOpenChange={(open) => {
        setSearchString("");
        setIsOpen(preventClose || open);
      }}
      classNames={classNames}
    >
      <PopoverTrigger>
        <Button
          variant="faded"
          radius="sm"
          className={trigger({ className })}
          {...props}
        >
          {label ? (
            <span className="truncate">
              {selectedOptions.map((v) => v.searchableValue).join(", ")}
            </span>
          ) : (
            <span className="truncate font-normal text-gray-900">
              {placeholder}
            </span>
          )}
          <ChevronDownIcon className="size-4 flex-none" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="space-y-4">
        <Input
          type="text"
          startContent={<MagnifyingGlassCircleIcon className="size-6" />}
          onChange={(e) => setSearchString(e.target.value)}
          value={searchString}
          className="pt-5"
          placeholder="Search"
          classNames={{
            inputWrapper:
              "border focus-within:border-0 focus-within:ring-2 focus-within:ring-blue-500 focus-within:border-blue-500",
            input: "border-0 ring-0 focus:ring-0",
          }}
        />
        {children}
        <Listbox
          aria-label={ariaLabel}
          className="max-h-[50vh] overflow-y-auto"
          selectedKeys={selectedValues || []}
          onSelectionChange={(keys) => {
            const selectAllOption = options.find(
              (option) => option.selectAllOption
            );
            if (
              selectAllOption &&
              [...keys].includes(selectAllOption.value) &&
              !selectedValues?.includes(selectAllOption.value)
            ) {
              onChange([selectAllOption.value]);
            } else if (
              selectAllOption &&
              selectedValues?.includes(selectAllOption.value) &&
              [...keys].some((key) => key !== selectAllOption.value)
            ) {
              onChange(
                [...keys]
                  .filter((k) => k.toString() !== selectAllOption.value)
                  .map((k) => k.toString())
              );
            } else {
              onChange([...keys].map((k) => k.toString()));
            }
            if (closeOnSelect) {
              setIsOpen(false);
            }
          }}
          selectionMode={selectionMode}
        >
          {options
            .filter((o) => {
              return o.searchableValue
                .toLowerCase()
                .includes(searchString.toLowerCase());
            })
            .map((o) => (
              <ListboxItem key={o.value} textValue={o.searchableValue}>
                {o.label}
              </ListboxItem>
            ))}
        </Listbox>
      </PopoverContent>
    </Popover>
  );
}

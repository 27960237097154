import { Checkbox, Listbox, ListboxItem, Selection } from "@nextui-org/react";
import { useInfiniteScroll } from "@nextui-org/use-infinite-scroll";
import { keyBy } from "lodash-es";
import { MutableRefObject, useState } from "react";

import { DropdownOptionsProps, DropdownOptionValue } from "./types";

export function DropdownOptions<T extends DropdownOptionValue>({
  ariaLabel,
  optionHeight,
  maxOptionsNumber,
  selectionMode,
  options,
  searchValue,
  hasMore,
  value,
  onChange,
  loadMore,
}: DropdownOptionsProps<T>) {
  // Using state so that the height doesn't change when the search feature is used
  const [listHeight] = useState(
    !hasMore && options.length < maxOptionsNumber
      ? options.length * optionHeight
      : maxOptionsNumber * optionHeight
  );
  const [, scrollerRef] = useInfiniteScroll({
    hasMore,
    onLoadMore: async () => {
      try {
        await loadMore();
      } catch {
        // avoiding unhandled promise rejection errors
      }
    },
    shouldUseLoader: false,
  });
  const optionsMap = keyBy(options, "value");
  const valuesMap = keyBy(value);
  const selectedValues = new Set(value);
  const displayedOptions = searchValue
    ? options.filter((o) =>
        o.label.toLowerCase().includes(searchValue.toLowerCase())
      )
    : options;

  const handleRef = (el: HTMLElement | null) => {
    if (el) {
      // Listbox doesn't provide a nicer way to pass the ref yet
      const ref = scrollerRef as MutableRefObject<HTMLElement | null>;

      ref.current = el;
    }
  };

  const handleSelectionChange = (selection: Selection) => {
    const keys = selection === "all" ? [] : [...selection];
    const newValue = keys.map((k) => optionsMap[k]?.value ?? valuesMap[k]!);

    onChange(newValue);
  };

  const handleCheckboxChange = (optionValue: T, isChecked: boolean) => {
    if (isChecked) {
      onChange([...value, optionValue]);
    } else {
      onChange(value.filter((v) => v !== optionValue));
    }
  };

  const optionElements = displayedOptions.map((option) => (
    <ListboxItem
      key={option.value.toString()}
      textValue={option.label}
      hideSelectedIcon={selectionMode === "multiple"}
      startContent={
        selectionMode === "multiple" ? (
          <Checkbox
            isSelected={selectedValues.has(option.value)}
            onValueChange={(v) => handleCheckboxChange(option.value, v)}
            aria-label={option.label}
          />
        ) : undefined
      }
      style={{ height: optionHeight }}
    >
      {option.label}
    </ListboxItem>
  ));

  if (hasMore) {
    optionElements.push(
      <ListboxItem
        key="$$loader$$"
        textValue="Loading..."
        style={{ height: optionHeight }}
      >
        <span className="sr-only">Loading...</span>
        <div className="h-4 w-full animate-pulse rounded-md bg-gray-100" />
      </ListboxItem>
    );
  }

  return (
    <Listbox
      ref={handleRef}
      aria-label={ariaLabel}
      selectionMode={selectionMode ?? "single"}
      onSelectionChange={handleSelectionChange}
      selectedKeys={value.map((v) => v.toString())}
      style={{ height: Math.max(listHeight, optionHeight), overflowY: "auto" }}
      emptyContent={"No selection found"}
      classNames={{
        base: "p-0",
        emptyContent: "text-sm font-medium text-gray-700 absolute",
      }}
    >
      {optionElements}
    </Listbox>
  );
}

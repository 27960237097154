import { Switch as NextUiSwitch, SwitchProps } from "@nextui-org/react";

export function Switch(props: SwitchProps) {
  return (
    <NextUiSwitch
      {...props}
      data-testid="revv-switch"
      aria-checked={props.isSelected}
      value={props.isSelected?.toString()}
    />
  );
}

import { get } from "lodash-es";
import {
  Controller,
  UseControllerProps,
  useFormContext,
} from "react-hook-form";

import {
  DropdownOptionValue,
  LegacyDropdown,
  LegacyDropdownProps,
  LegacyMultiDropdown,
  LegacyMultiDropdownProps,
} from "../LegacyDropdown/LegacyDropdown";
import { FormErrorMessage } from "./FormErrorMessage";

export type LegacyFormDropdownProps<T extends DropdownOptionValue> = Omit<
  LegacyDropdownProps<T>,
  "onChange"
> & {
  name: string;
  rules?: UseControllerProps["rules"];
};

export function LegacyFormDropdown<T extends DropdownOptionValue>({
  ...props
}: LegacyFormDropdownProps<T>) {
  return <FormBaseDropdown mode="single" {...props} />;
}

export type LegacyFormMultiDropdownProps<T extends DropdownOptionValue> = Omit<
  LegacyMultiDropdownProps<T>,
  "onChange"
> & {
  name: string;
  rules?: UseControllerProps["rules"];
};

export function LegacyFormMultiDropdown<T extends DropdownOptionValue>({
  ...props
}: LegacyFormDropdownProps<T>) {
  return <FormBaseDropdown mode="multi" {...props} />;
}

type InnerDropdownProps<T extends DropdownOptionValue> = Omit<
  LegacyMultiDropdownProps<T>,
  "onChange"
> & {
  name: string;
  rules?: UseControllerProps["rules"];
  mode: "single" | "multi";
};

function FormBaseDropdown<T extends DropdownOptionValue>({
  name,
  rules,
  options,
  className,
  mode,
  ...props
}: InnerDropdownProps<T>) {
  const { control } = useFormContext();
  const Cmp = mode === "single" ? LegacyDropdown : LegacyMultiDropdown;

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, formState }) => {
        const isInvalid = !!get(formState.errors, name);
        const cName = `${isInvalid ? "border-red-500" : ""} ${className ?? ""}`;

        return (
          <>
            <Cmp
              {...props}
              className={cName}
              aria-label={name}
              aria-invalid={isInvalid}
              aria-errormessage={`${name}-error`}
              selectedValue={field.value ?? ""}
              selectedValues={field.value ?? []}
              options={options}
              onChange={(...args) => {
                // Temp fix for tests, user.type stops working after dropdown change
                setTimeout(() => field.onChange(...args));
              }}
            />
            <FormErrorMessage name={name} />
          </>
        );
      }}
    />
  );
}

import { SlotsToClasses } from "@nextui-org/react";
import { ReactNode, useEffect, useRef, useState } from "react";
import { tv } from "tailwind-variants";

const contextMenu = tv({
  slots: {
    base: "",
    mainWrapper: "",
    innerWrapper: "overflow-hidden rounded-md bg-white opacity-100 shadow-lg",
  },
});

type ContextMenuProps = {
  children: ReactNode;
  content: ReactNode;
  classNames?: SlotsToClasses<keyof ReturnType<typeof contextMenu>>;
};

type Points = {
  x: number;
  y: number;
};

const { base, mainWrapper, innerWrapper } = contextMenu();

export function ContextMenu({
  children,
  content,
  classNames,
}: ContextMenuProps) {
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const [points, setPoints] = useState<Points>({
    x: 0,
    y: 0,
  });

  useEffect(() => {
    const handleClick = () => {
      setOpen(false);
    };

    if (open) {
      document.addEventListener("click", handleClick);
    }

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [open]);

  return (
    <div className={base({ className: classNames?.base })}>
      <div
        className={mainWrapper({ className: classNames?.mainWrapper })}
        onContextMenu={(e) => {
          e.preventDefault();
          setOpen(true);
          setPoints({
            x: e.pageX,
            y: e.pageY,
          });
        }}
      >
        {children}
      </div>
      {open && (
        <div
          ref={ref}
          style={{
            left: points.x,
            top: points.y,
            position: "fixed",
            zIndex: 1000,
          }}
          role="presentation"
          className={innerWrapper({ className: classNames?.innerWrapper })}
        >
          {content}
        </div>
      )}
    </div>
  );
}

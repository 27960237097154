import JsonViewComponent from "@uiw/react-json-view";

type JsonViewProps = {
  data: object;
};

export function JsonView({ data }: JsonViewProps) {
  return (
    <JsonViewComponent
      value={data}
      displayDataTypes={false}
      collapsed={false}
      shortenTextAfterLength={0}
    />
  );
}

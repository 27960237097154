import { get } from "lodash-es";
import { ComponentProps } from "react";
import {
  Controller,
  UseControllerProps,
  useFormContext,
} from "react-hook-form";

import { TimeInput } from "../TimeInput/TimeInput";
import { FormErrorMessage } from "./FormErrorMessage";

export type FormTimeInputProps = ComponentProps<typeof TimeInput> & {
  name: string;
  rules?: UseControllerProps["rules"];
};

export function FormTimeInput({
  name,
  rules,
  classNames,
  ...props
}: FormTimeInputProps) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, formState }) => {
        const isInvalid = !!get(formState.errors, name);

        return (
          <>
            <TimeInput
              {...props}
              classNames={{
                ...classNames,
              }}
              aria-label={name}
              aria-invalid={isInvalid}
              aria-errormessage={`${name}-error`}
              name={name}
              value={field.value}
              onChange={field.onChange}
            />
            <FormErrorMessage name={name} />
          </>
        );
      }}
    />
  );
}

import { ChevronDownIcon } from "@heroicons/react/24/outline";
import {
  Button,
  ButtonProps,
  cn,
  Dropdown as NextDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownMenuProps,
  DropdownTrigger,
  SelectionMode,
} from "@nextui-org/react";
import { keyBy } from "lodash-es";
import { Fragment, ReactNode, useMemo } from "react";

import { mergeClassNames } from "../utils";

export type DropdownOptionValue = string | boolean | number;

export type DropdownOption<T extends DropdownOptionValue = string> = {
  value: T;
  label: ReactNode;
};

export type LegacyDropdownProps<T extends DropdownOptionValue> = Omit<
  InnerDropdownProps<T>,
  "selectionMode" | "selectedValues" | "onChange"
> & {
  selectedValue?: T;
  onChange: (value?: T) => void;
};

export function LegacyDropdown<T extends DropdownOptionValue>({
  selectedValue,
  onChange,
  ...props
}: LegacyDropdownProps<T>) {
  const selectedKeys = useMemo(
    () => (selectedValue === undefined ? undefined : [selectedValue]),
    [selectedValue]
  );

  const handleChange = (values?: T[]) => {
    onChange([...(values || [])][0]);
  };

  return (
    <BaseDropdown<T>
      {...props}
      selectedValues={selectedKeys}
      onChange={handleChange}
      selectionMode="single"
    />
  );
}

export type LegacyMultiDropdownProps<T extends DropdownOptionValue> = Omit<
  InnerDropdownProps<T>,
  "selectionMode"
>;

export function LegacyMultiDropdown<T extends DropdownOptionValue>(
  props: LegacyMultiDropdownProps<T>
) {
  return <BaseDropdown<T> {...props} selectionMode="multiple" />;
}

type InnerDropdownProps<T extends DropdownOptionValue> = Omit<
  ButtonProps,
  "onChange"
> &
  Pick<DropdownMenuProps, "disallowEmptySelection"> & {
    selectedValues?: T[];
    placeholder?: string;
    options: DropdownOption<T>[];
    onChange: (values?: T[]) => void;
    selectionMode: SelectionMode;
    ariaLabel?: string;
    classNames?: DropdownMenuProps["classNames"];
    closeOnSelect?: boolean;
  };

function BaseDropdown<T extends DropdownOptionValue>({
  selectedValues,
  selectionMode,
  placeholder,
  options = [],
  onChange,
  ariaLabel = "dropdown",
  className,
  classNames,
  closeOnSelect,
  disallowEmptySelection,
  ...props
}: InnerDropdownProps<T>) {
  const optionsMap = keyBy(options, "value");
  // Interating over the options so that order matches
  const selectedOptions =
    options.filter((v) => selectedValues?.includes(v.value)) || [];
  const labels =
    selectedOptions.length > 0
      ? selectedOptions.map((v, i) => (
          <Fragment key={v.value.toString()}>
            {v.label}
            {i === selectedOptions.length - 1 ? "" : ", "}
          </Fragment>
        ))
      : undefined;

  return (
    <NextDropdown>
      <DropdownTrigger>
        <Button
          variant="faded"
          radius="sm"
          className={cn("flex justify-between overflow-hidden", className)}
          {...props}
        >
          {labels ? (
            <span className="truncate">{labels}</span>
          ) : (
            <span className="truncate font-normal text-gray-900">
              {placeholder}
            </span>
          )}
          <ChevronDownIcon className="size-4 flex-none" />
        </Button>
      </DropdownTrigger>
      <DropdownMenu
        aria-label={ariaLabel}
        classNames={mergeClassNames(
          { base: "max-h-96 overflow-y-auto" },
          { ...classNames }
        )}
        onSelectionChange={(keys) => {
          const values = [...keys].reduce<T[]>((acc, k) => {
            const val = optionsMap[k]?.value;

            if (val !== undefined) {
              acc.push(val);
            }

            return acc;
          }, []);

          onChange(values);
        }}
        selectionMode={selectionMode}
        selectedKeys={selectedValues?.map((v) => v.toString()) || []}
        closeOnSelect={closeOnSelect}
        disallowEmptySelection={disallowEmptySelection}
      >
        {options.map((o) => (
          <DropdownItem key={o.value.toString()}>{o.label}</DropdownItem>
        ))}
      </DropdownMenu>
    </NextDropdown>
  );
}

import {
  Button as NextUiButton,
  ButtonProps as NextUiButtonProps,
} from "@nextui-org/react";
import { ComponentProps, forwardRef } from "react";

export const Button = forwardRef<HTMLButtonElement, NextUiButtonProps>(
  (props, ref) => <NextUiButton {...props} ref={ref} />
);

export type ButtonProps = ComponentProps<typeof Button>;

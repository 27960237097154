import {
  Popover,
  PopoverContent,
  PopoverProps,
  PopoverTrigger,
} from "@nextui-org/react";
import { ReactNode, useState } from "react";

import { mergeClassNames } from "../utils";

type PopoverMenuProps = Pick<
  PopoverProps,
  | "placement"
  | "offset"
  | "shouldBlockScroll"
  | "triggerScaleOnOpen"
  | "classNames"
> & {
  children: ReactNode;
  content: ReactNode;
  className?: string;
} & (
    | {
        isOpen?: boolean;
        onOpenChange?: (v: boolean) => void;
        autoClose?: never;
      }
    | {
        isOpen?: never;
        onOpenChange?: never;
        autoClose: true;
      }
  );

export function PopoverMenu({
  children,
  content,
  className,
  isOpen,
  onOpenChange,
  autoClose,
  classNames,
  ...popoverProps
}: PopoverMenuProps) {
  const [isLocalOpen, setIsLocalOpen] = useState(false);

  const handleOpenChange = (v: boolean) => {
    if (autoClose) {
      setIsLocalOpen(v);
    } else if (onOpenChange) {
      onOpenChange(v);
    }
  };

  const handleAutoCloseContentClick = () => {
    setIsLocalOpen(false);
  };

  return (
    <Popover
      isOpen={autoClose ? isLocalOpen : isOpen}
      radius="sm"
      {...popoverProps}
      shouldCloseOnBlur
      onOpenChange={handleOpenChange}
      classNames={mergeClassNames(
        { content: "p-0 overflow-hidden" },
        classNames ?? {}
      )}
    >
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContent
        // Using the capture phase to catch events that won't bubble up
        // because of possible stopPropagation calls in the content
        onClickCapture={autoClose ? handleAutoCloseContentClick : undefined}
        className={className}
      >
        {content}
      </PopoverContent>
    </Popover>
  );
}

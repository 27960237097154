import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import {
  Button,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@nextui-org/react";
import { partition } from "lodash-es";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useDebounceValue } from "usehooks-ts";

import { Input } from "../Input/Input";
import { DropdownOptions } from "./DropdownOptions";
import { DropdownTrigger } from "./DropdownTrigger";
import { DropdownOptionValue, DropdownProps } from "./types";

export function Dropdown<T extends DropdownOptionValue>({
  placeholder = "Select value",
  ariaLabel = "dropdown",
  optionsWidth,
  optionHeight = 32,
  maxOptionsNumber = 8,
  selectionMode,
  closeOnSelect = selectionMode !== "multiple",
  value,
  onChange,
  isSearchable = false,
  options: syncOptions,
  useOptions = useOptionsDefault,
  useOptionsExtraParams = [],
  triggerClassName,
  isLoading,
  error,
}: DropdownProps<T>) {
  const triggerRef = useRef<HTMLButtonElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [debounceSearchValue, setSearchValueDebounced] = useDebounceValue(
    searchValue,
    300
  );
  const {
    options: asyncOptions,
    hasMore,
    loadMore,
    isLoading: asyncIsLoading,
    error: asyncError,
  } = useOptions(value, debounceSearchValue, ...useOptionsExtraParams);
  const [innerValue, setInnerValue] = useState(valueToInnerValue(value));

  useEffect(() => {
    setInnerValue(valueToInnerValue(value));
  }, [value]);

  let options = syncOptions ?? asyncOptions;

  if (selectionMode === "multiple") {
    const selectedValues = new Set(valueToInnerValue(value));
    const [selectedOptions, unselectedOptions] = partition(options, (o) =>
      selectedValues.has(o.value)
    );

    options = [...selectedOptions, ...unselectedOptions];
  }

  const changeSearchValue = (str: string) => {
    setSearchValue(str);
    setSearchValueDebounced(str);
  };

  const handlePopoverRef = (popoverEl: HTMLElement | null) => {
    if (popoverEl) {
      const triggerEl = triggerRef.current;

      if (isOpen && triggerEl && popoverEl) {
        popoverEl.style.width = optionsWidth ?? `${triggerEl.offsetWidth}px`;
      }
    }
  };

  const handleOpenChange = (open: boolean) => {
    if (open) {
      changeSearchValue("");
    } else if (!closeOnSelect) {
      setInnerValue(valueToInnerValue(value));
    }

    setIsOpen(open);
  };

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    changeSearchValue(e.target.value);
  };

  const handleChange = (val: T[]) => {
    if (closeOnSelect) {
      if (selectionMode === "multiple") {
        onChange(val);
      } else {
        onChange(val[0]);
      }

      setIsOpen(false);
    } else {
      setInnerValue(val);
    }
  };

  const handleClearAll = () => {
    if (closeOnSelect) {
      if (selectionMode === "multiple") {
        onChange([]);
      } else {
        onChange();
      }
    } else {
      setInnerValue([]);
    }
  };

  const handleApply = () => {
    setIsOpen(false);

    if (selectionMode === "multiple") {
      onChange(innerValue);
    }
  };

  return (
    <Popover
      size="sm"
      isOpen={isOpen}
      onOpenChange={handleOpenChange}
      placement="bottom"
      offset={4}
      shouldBlockScroll={true}
    >
      <PopoverTrigger>
        <DropdownTrigger
          triggerRef={triggerRef}
          placeholder={placeholder}
          options={options}
          value={value}
          className={triggerClassName}
          isLoading={isLoading || asyncIsLoading}
          error={error || asyncError}
        />
      </PopoverTrigger>
      <PopoverContent className="overflow-hidden rounded-md p-0">
        <div
          data-testid="dropdown-popover"
          ref={handlePopoverRef}
          className="flex flex-col gap-4 p-4"
        >
          {isSearchable && (
            <div>
              <Input
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
                placeholder="Search"
                value={searchValue}
                onChange={handleSearchChange}
                startContent={
                  <MagnifyingGlassIcon className="size-5 text-gray-500" />
                }
                classNames={{
                  inputWrapper:
                    "focus-within:!ring-0 focus-within:!ring-offset-0",
                }}
              />
            </div>
          )}

          {selectionMode === "multiple" && innerValue.length > 0 && (
            <div className="flex justify-between">
              <div className="text-sm font-medium text-gray-500">
                {innerValue.length} Selected
              </div>

              <button
                type="button"
                className="text-sm font-medium text-blue-600"
                onClick={handleClearAll}
              >
                Clear all
              </button>
            </div>
          )}

          <div className="-mx-2">
            <DropdownOptions
              ariaLabel={ariaLabel}
              optionHeight={optionHeight}
              maxOptionsNumber={maxOptionsNumber}
              selectionMode={selectionMode ?? "single"}
              searchValue={debounceSearchValue}
              hasMore={hasMore}
              options={options}
              value={innerValue}
              onChange={handleChange}
              loadMore={loadMore}
            />
          </div>

          {!closeOnSelect && (
            <Button size="sm" color="primary" onClick={handleApply} fullWidth>
              Apply
            </Button>
          )}
        </div>
      </PopoverContent>
    </Popover>
  );
}

// We have to provide a mock hook so that we don't call hooks conditionally
function useOptionsDefault() {
  return {
    options: [],
    hasMore: false,
    loadMore: () => Promise.resolve(),
    isLoading: false,
  };
}

function valueToInnerValue<T extends DropdownOptionValue>(value?: T | T[]) {
  return value === undefined ? [] : Array.isArray(value) ? value : [value];
}
